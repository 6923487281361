import { Injectable } from '@angular/core';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { Alert, AlertFlashService } from '@callrail/looky/ui';
import {
  AppRoutes,
  LocationService,
  WindowRefService,
} from '@callrail/looky/util';
import { Auth, Hub } from 'aws-amplify';
import { CognitoCookieService } from '../cognito-cookie/cognito-cookie.service';
import { catchError, Observable, of, tap } from 'rxjs';

import { HeaderTokenAuthService } from '../header-token-auth/header-token-auth.service';
import { RingCentralIframeService } from '../ring-central-iframe/ring-central-iframe.service';

@Injectable({
  providedIn: 'root',
})
export class UserSignoutService {
  private hubListenerCancelToken: () => void;

  constructor(
    private readonly authenticator: AuthenticatorService,
    private readonly locationService: LocationService,
    private cognitoCookieService: CognitoCookieService,
    private windowRefService: WindowRefService,
    private ringCentralIframeService: RingCentralIframeService,
    private headerTokenAuthService: HeaderTokenAuthService,
    private alertService: AlertFlashService
  ) {}

  private get redirectRoute(): string {
    return this.windowRefService.isRingCentralIframe
      ? '/ring-central/sign-out-cookie-session'
      : '/users/sign_out';
  }

  private signOutRingCentralOrNot(): Observable<unknown> {
    if (!this.windowRefService.isRingCentralIframe) {
      return of({});
    }
    return this.ringCentralIframeService.signOut.pipe(
      tap(() => (this.headerTokenAuthService.jwt = null)),
      catchError(() => {
        this.headerTokenAuthService.jwt = null;
        return of({});
      })
    );
  }

  public userSignout(): void {
    // we need to destroy the rc session_token before signing out in any other way (standard or cognito)
    // cuz the rc session_token sign_out endpoint requires the user to be authenticated for it to succeed
    this.signOutRingCentralOrNot().subscribe({
      next: () => {
        this.cognitoCookieService.signOut();
        this.signoutCognito();
      },
      error: (err) => {
        console.error(err);
      },
    });
  }

  public userTimeout(): void {
    this.alertService.addAlert(
      new Alert({
        type: 'info',
        text: 'Your session expired. Please sign in again to continue.',
        crossApp: true,
        app: AppRoutes.ANUBIS,
      })
    );
    this.signoutCognito();
  }

  private standardSignout(): void {
    if (this.hubListenerCancelToken) {
      this.hubListenerCancelToken();
    }

    if (this.windowRefService.isRingCentralIframe) {
      const options =
        'status=0,toolbar=0,menubar=0,resizable=0,scrollbars=1,top=50,left=50,height=680,width=680';
      this.locationService.open(this.redirectRoute, '_blank', options);
      this.locationService.go(
        '/authy/session/providers/call_rail/waiting?noAutoOpen=1',
        true
      );
    } else {
      this.locationService.go(this.redirectRoute, true);
    }
  }

  private signoutCognito(): void {
    this.hubListenerCancelToken = Hub.listen('auth', (data) => {
      if (data?.payload?.event === 'signOut') {
        this.standardSignout();
      }
    });
    Auth.signOut({ global: true })
      .then(() => {
        // if token has been revoked, signout will continue
        this.standardSignout();
      })
      .catch(() => {
        this.standardSignout();
      });
  }
}
