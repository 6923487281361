import { APP_BASE_HREF, Location } from '@angular/common'; // eslint-disable-line local-rules/no-ng-location
import { Inject, Injectable, Optional } from '@angular/core';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { ParamsService } from '../params/params.service';
import { WindowRefService } from '../window-ref/window-ref.service';
import { getSafeUrl } from '../../functions/get-safe-url/get-safe-url';

declare const Sfdc: any; // salesforce canvas js sdk

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(
    private ngLocation: Location, // eslint-disable-line local-rules/no-ng-location
    private params: ParamsService,
    private windowRefService: WindowRefService,
    @Optional() private authenticator: AuthenticatorService,
    @Optional() @Inject('nativeSpecLocation') private nativeSpecLocation?: any,
    @Optional()
    @Inject('salesforceCanvasSpecClient')
    private salesforceCanvasSpecClient?: any,
    @Optional() @Inject(APP_BASE_HREF) private appBaseHref?: string // `/lead-center/`
  ) {}

  public go(url: string, refresh = false): void {
    if (refresh) {
      this.handleRefreshGo(url);
    } else {
      this.ngLocation.go(url);
    }
  }

  public goToSignIn(): void {
    if (this.authenticator?.user) {
      this.go('/authenticate', true);
    } else {
      this.go('/users/sign_in', true);
    }
  }

  public goToSignOut(): void {
    this.go(this.origin + '/authenticate/sign_out', true);
  }

  public open(url: string, target?: string, windowFeatures?: string): Window {
    if (this.windowRefService.isSalesforceCanvas) {
      return this.handleSalesforceCanvasOpen(url, target, windowFeatures);
    } else if (this.windowRefService.isSemrushIframe) {
      return this.handleSemrushRefreshGo(url);
    } else if (this.needsNewRingCentralTab(url)) {
      return this.windowRefService
        .nativeWindow()
        .open(url, '_blank', windowFeatures); // eslint-disable-line local-rules/no-window-open
    } else {
      return this.windowRefService
        .nativeWindow()
        .open(url, target, windowFeatures); // eslint-disable-line local-rules/no-window-open
    }
  }

  public openNewTab(externalUrl: string): Window {
    const win = this.open(externalUrl, '_blank');
    win?.focus(); // this can be null if popup permissions are denied in browser
    return win;
  }

  public reload(): void {
    if (this.windowRefService.isSalesforceCanvas) {
      this.reloadSalesforceCanvas();
    } else if (this.windowRefService.isSemrushIframe) {
      this.handleSemrushRefreshGo(this.href);
    } else {
      this.nativeLocation.reload();
    }
  }

  public assign(url: string): void {
    if (this.windowRefService.isSalesforceCanvas) {
      this.handleSalesforceCanvasGo(url);
    } else if (this.windowRefService.isSemrushIframe) {
      this.handleSemrushRefreshGo(url);
    } else if (this.needsNewRingCentralTab(url)) {
      this.openNewTab(url);
    } else {
      this.nativeLocation.assign(url);
    }
  }

  public prevUrl(): void {
    this.ngLocation.historyGo(-2);
  }

  public back(): void {
    this.ngLocation.back();
  }

  public replaceState(state: string): void {
    this.ngLocation.replaceState(state);
  }

  public removeParams(paramNames: string[]): void {
    const params = this.params.removeKeys(this.search(), paramNames);
    const ngPath = this.path().split('?')[0];
    this.replaceState(this.params.appendToString(ngPath, params));
  }

  public prepareExternalUrl(url: string): string {
    return this.ngLocation.prepareExternalUrl(url);
  }

  public host(): string {
    return this.nativeLocation.host;
  }

  public path(): string {
    return this.ngLocation.path();
  }

  public get href(): string {
    return this.nativeLocation.href;
  }

  public get pathname(): string {
    return this.nativeLocation.pathname;
  }

  public get hostname(): string {
    return this.nativeLocation.hostname;
  }

  public absPath(): string {
    return `${this.pathname}${this.nativeLocation.search}`;
  }

  public search(): any {
    return this.params.fromString(this.nativeLocation.search);
  }

  public get protocol(): string {
    return this.nativeLocation.protocol;
  }

  public get origin(): string {
    return this.nativeLocation.origin;
  }

  public isPathMatching(regex: RegExp): boolean {
    return !!this.path().replace(/\?.*/, '').match(regex);
  }

  private pathFrom(url: string) {
    return url.replace(/.*\/\/[^\/]*/, '').replace(/\/?\?.*/, '');
  }

  private isCurrentPath(url: string): boolean {
    return this.pathname === this.pathFrom(url);
  }

  private isAnalyticsPath(url: string): boolean {
    return /^\/analytics/.test(this.pathFrom(url));
  }

  private isSettingsPath(url: string): boolean {
    return /^\/settings/.test(this.pathFrom(url));
  }

  private isCfbPath(url: string): boolean {
    return /^\/cfb/.test(this.pathFrom(url));
  }

  private isAuthyPath(url: string): boolean {
    return /^\/authy/.test(this.pathFrom(url));
  }

  private isCallRailAppDomain(url: string): boolean {
    return (
      !url.includes('http') ||
      !!this.host().match(/(app|pipe)\.callrail\.com$/) ||
      !!this.host().match(/-callrail\.com$/) ||
      !!this.host().match(/localhost/)
    );
  }

  private isValidSemrushPath(url: string): boolean {
    return (
      this.isAuthyPath(url) ||
      this.isCfbPath(url) ||
      this.isSettingsPath(url) ||
      this.isAnalyticsPath(url) ||
      (!url.includes(this.appBaseHref) && !url.includes('superadmin'))
    );
  }

  private needsNewRingCentralTab(url: string): boolean {
    return (
      this.windowRefService.isRingCentralIframe &&
      !this.isAnalyticsPath(url) &&
      !this.isAuthyPath(url)
    );
  }

  private reloadSalesforceCanvas(): void {
    (this.salesforceCanvasSpecClient || Sfdc.canvas.client).repost(true);
  }

  private handleRefreshGo(url: string): void {
    if (this.windowRefService.isSalesforceCanvas) {
      this.handleSalesforceCanvasGo(url);
    } else if (this.windowRefService.isSemrushIframe) {
      this.handleSemrushRefreshGo(url);
    } else if (this.needsNewRingCentralTab(url)) {
      this.openNewTab(url);
    } else {
      this.nativeLocation.href = getSafeUrl(url);
    }
  }

  private handleSalesforceCanvasGo(url: string): void {
    if (this.isCurrentPath(url)) {
      this.reloadSalesforceCanvas();
    } else {
      this.openNewTab(url);
    }
  }

  private handleSalesforceCanvasOpen(
    url: string,
    target?: string,
    windowFeatures?: string
  ): Window {
    if (target === '_self' && this.isCurrentPath(url)) {
      this.reloadSalesforceCanvas();
    } else {
      return this.windowRefService
        .nativeWindow()
        .open(url, '_blank', windowFeatures); // eslint-disable-line local-rules/no-window-open
    }
  }

  private handleSemrushRefreshGo(url: string): Window {
    if (this.isCallRailAppDomain(url) && this.isValidSemrushPath(url)) {
      url = this.params.appendToString(url, { is_semrush_iframe: true });
      this.nativeLocation.href = url;
      return this.windowRefService.nativeWindow(); // eslint-disable-line local-rules/no-window-open
    } else {
      console.warn('tried to visit url: ' + url); // eslint-disable-line no-console
      const error = new Error('Semrush: Invalid semrush destination');
      (error as any).url = url; // picked up in HB context/metadata
      throw error;
    }
  }

  // keep this function private! If you need to do something with it that is
  // not yet implemented above, add a new function that does what you need
  private get nativeLocation(): any {
    return this.nativeSpecLocation || location;
  }
}
